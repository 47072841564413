import { useState } from 'react';
import { useRouter } from 'next/router';

import { AvatarImg } from '@playbooks/interface/avatars';
import {
	Drop,
	DropBtn,
	DropHeader,
	DropItem,
	DropLink,
	DropList,
	DropMenu,
	DropToggle,
} from '@playbooks/interface/drops';
import { UserAboutCard } from 'components/user/user-about-card';
import { useSession } from 'contexts';

const UserDrop = ({ type, user, rootLink, onLogout, onNext }) => {
	const [open, setOpen] = useState(false);
	const router = useRouter();
	const session = useSession();

	// Methods
	const onToggle = () => setOpen(!open);

	const onSwitch = () => {
		setOpen(!open);
		onNext();
	};

	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop open={open} setOpen={onClose}>
			<DropToggle type={type} size='xxs' nextIcon='' active={open} onClick={onToggle}>
				<AvatarImg size='w-7 h-7' src={user.thumbnail} alt='user thumbnail' />
			</DropToggle>

			<DropMenu open={open} spacing='right-0 mt-4' width='w-96'>
				<DropHeader spacing='p-4'>
					<UserAboutCard user={user} />
				</DropHeader>

				<DropList>
					<DropItem>
						<DropLink prevIcon='circle-user' href={rootLink}>
							Profile
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='code' href={`${rootLink}/repos`}>
							Repos
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='sack-dollar' href={`${rootLink}/bounties`}>
							Bounties
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='layer-group' href={`${rootLink}/collections`}>
							Collections
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='bookmark' href={`${rootLink}/bookmarks`}>
							Bookmarks
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='building' href={`${rootLink}/teams`}>
							Teams
						</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropLink prevIcon='cart-shopping' href={`/orders`}>
							Orders
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='arrow-down-to-line' href={`/downloads`}>
							Downloads
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='handshake' href={`/contributions`}>
							Contributions
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='message' href={`/support`}>
							Support
						</DropLink>
					</DropItem>

					<DropItem>
						<DropLink prevIcon='gear' href={`/settings`}>
							Settings
						</DropLink>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					<DropItem>
						<DropBtn prevIcon='toggle-on' onClick={onSwitch}>
							Switch Account
						</DropBtn>
					</DropItem>

					<DropItem border='border-b' spacing='px-2' />

					{session.user.role === 'admin' && (
						<DropItem>
							{router.pathname.includes('/admin') ? (
								<DropLink prevIcon='arrow-right-from-bracket' href='/'>
									Exit Admin
								</DropLink>
							) : (
								<DropLink prevIcon='arrow-right-to-bracket' href='/admin'>
									View Admin
								</DropLink>
							)}
						</DropItem>
					)}
					<DropItem>
						<DropBtn prevIcon='arrow-right-from-bracket' onClick={onLogout}>
							Logout
						</DropBtn>
					</DropItem>
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { UserDrop };
