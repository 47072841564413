import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { Btn } from '@playbooks/interface/buttons';
import { useStore, useToast } from 'contexts';
import { getUUID, log } from 'utils';

const GithubOAuthBtn = ({ onNext }) => {
	const [authWindow, setAuthWindow] = useState(null);
	const [taskRunning, setTaskRunning] = useState(false);
	const router = useRouter();
	const store = useStore();
	const toast = useToast();

	// Computed
	const authUrl = useMemo(() => {
		const baseUrl = new URL('https://github.com/login/oauth/authorize');
		baseUrl.searchParams.append('client_id', process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID);
		baseUrl.searchParams.append('scope', ['user:email', 'read:user', 'read:team', 'repo'].join(','));
		baseUrl.searchParams.append('redirect_uri', process.env.NEXT_PUBLIC_WEB_DOMAIN + '/oauth');
		baseUrl.searchParams.append('state', getUUID());
		return baseUrl;
	}, []);

	// Hooks
	useEffect(() => {
		if (authWindow) window.addEventListener('message', onListen);
		return () => window.removeEventListener('message', onListen);
	}, [authWindow]);

	// Methods
	const fetchData = async ({ code, state }) => {
		try {
			setTaskRunning(true);
			const response = await store.saveRecord({ url: `/oauth/github-auth`, data: { code, state } });
			await onNext(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	// Methods
	const onClick = async () => {
		const windowOptions = 'width=600,height=700,toolbar=no,menubar=no,top=auto,left=auto';
		const authWindow = window.open(authUrl, '_blank', windowOptions);
		setAuthWindow(authWindow);
	};

	function onListen(event) {
		const domain = process.env.NEXT_PUBLIC_WEB_DOMAIN;
		if (event.origin !== domain) return;
		switch (event.data.callbackType) {
			case 'onGithub':
				return onGithub(event.data, authWindow);
		}
	}

	const onGithub = (data, authWindow) => {
		log('github: ', { code: data.code, state: data.state });
		fetchData({ code: data.code, state: data.state });
		authWindow.close();
	};

	// Render
	return (
		<Btn
			size='lg'
			bgColor='bg-gray-700 dark:bg-gray-800 h:bg-gray-600 dark:h:bg-gray-700'
			prevIcon={{ type: 'fab', icon: 'github' }}
			onClick={() => onClick()}
			taskRunning={taskRunning}
			className='w-full'>
			Login with Github
		</Btn>
	);
};

export { GithubOAuthBtn };

// Docs
// https://github.com/octokit/oauth-app.js/#appgetwebflowauthorizationurloptions
// https://docs.github.com/en/developers/apps/building-oauth-apps/scopes-for-oauth-apps
// https://medium.com/front-end-weekly/use-github-oauth-as-your-sso-seamlessly-with-react-3e2e3b358fa1
