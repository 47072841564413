import { Avatar, AvatarBody, AvatarImg, AvatarText, AvatarTitle } from '@playbooks/interface/avatars';
import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';

export const ModalAvatar = ({ thumbnail, title, subtitle, badge }) => {
	// Render
	return (
		<Avatar display='flex-between'>
			<Span display='flex-start' space='space-x-4'>
				<AvatarImg size='md' src={thumbnail} alt={`${title} photo`} />
				<AvatarBody decoration='group-hover:underline'>
					<AvatarTitle>{title}</AvatarTitle>
					<AvatarText>{subtitle}</AvatarText>
				</AvatarBody>
			</Span>
			<Badge>{badge}</Badge>
		</Avatar>
	);
};
