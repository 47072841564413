import { CardAvatar, CommentAvatar, HeroAvatar, MetaAvatar, TableAvatar, ThumbnailAvatar } from 'molecules/avatars';
import { Skeleton } from 'molecules/skeletons';

export const UserAvatar = ({ type = 'card', user, loading = false, rootLink = '' }) => {
	const props = {
		icon: null,
		thumbnail: user.thumbnail,
		title: user.name,
		subtitle: `@${user.uuid}`,
		href: type === 'table' ? `${rootLink}/users/${user.uuid}` : `${rootLink}/${user.uuid}`,
		badge: user.role,
	};

	// Render
	if (loading) return <Skeleton className='w-20' />;
	switch (type) {
		case 'card':
			return <CardAvatar {...props} />;
		case 'comment':
			return <CommentAvatar {...props} />;
		case 'hero':
			return <HeroAvatar {...props} />;
		case 'meta':
			return <MetaAvatar {...props} />;
		case 'table':
			return <TableAvatar {...props} />;
		case 'thumbnail':
			return <ThumbnailAvatar {...props} />;
	}
};
