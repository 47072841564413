import { CardAvatar, CommentAvatar, HeroAvatar, MetaAvatar, TableAvatar, ThumbnailAvatar } from 'molecules/avatars';
import { Skeleton } from 'molecules/skeletons';

export const TeamAvatar = ({ type = 'card', team, loading = false, rootLink = '' }) => {
	const props = {
		icon: '',
		thumbnail: team.thumbnail,
		title: team.name,
		subtitle: `@${team.uuid}`,
		href: `${rootLink}/teams/${team.uuid}`,
		badge: team.role,
	};

	// Render
	if (loading) return <Skeleton className='w-20' />;
	switch (type) {
		case 'card':
			return <CardAvatar {...props} />;
		case 'comment':
			return <CommentAvatar {...props} />;
		case 'hero':
			return <HeroAvatar {...props} />;
		case 'meta':
			return <MetaAvatar {...props} />;
		case 'table':
			return <TableAvatar {...props} />;
		case 'thumbnail':
			return <ThumbnailAvatar {...props} />;
	}
};
