import { Fragment, useState } from 'react';

import { AccountSwitchModal } from 'components/account/account-switch-modal';
import { GuestDrop } from 'components/guest/guest-drop';
import { TeamDrop } from 'components/team/team-drop';
import { UserDrop } from 'components/user/user-drop';
import { useAccount, useSession } from 'contexts';

const AccountDrop = ({ type = 'accent' }) => {
	const [open, setOpen] = useState(false);
	const account = useAccount();
	const session = useSession();

	// Methods
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);
	const onLogout = async () => {
		session.onLogout();
	};

	// Render
	return (
		<Fragment>
			{account.account.id ? (
				<Fragment>
					{account.type === 'User' && (
						<UserDrop
							type={type}
							user={account.account || {}}
							rootLink={account.rootLink}
							onLogout={onLogout}
							onNext={onOpen}
						/>
					)}
					{account.type === 'Team' && (
						<TeamDrop
							type={type}
							team={account.account || {}}
							rootLink={account.rootLink}
							onLogout={onLogout}
							onNext={onOpen}
						/>
					)}
				</Fragment>
			) : (
				<GuestDrop type={type} rootLink={account.rootLink} onNext={onOpen} />
			)}
			<AccountSwitchModal open={open} setOpen={setOpen} onNext={onClose} />
		</Fragment>
	);
};

export { AccountDrop };
