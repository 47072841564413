import { Avatar, AvatarBody, AvatarImg, AvatarText } from '@playbooks/interface/avatars';
import { FarIcon } from '@playbooks/interface/icons';
import { LinkWrapper } from '@playbooks/interface/links';
import { truncate } from 'utils';

export const TableAvatar = ({ icon, thumbnail, title, href }) => {
	// Render
	return (
		<LinkWrapper href={href} group='group' flex='shrink-0'>
			<Avatar display='flex-start' flex='shrink-0'>
				{icon && <FarIcon icon={icon} />}
				{thumbnail && <AvatarImg size='xs' src={thumbnail} alt={`${title} photo`} />}
				<AvatarBody decoration='group-hover:underline' flex='shrink-0'>
					<AvatarText>{truncate(title, 40)}</AvatarText>
				</AvatarBody>
			</Avatar>
		</LinkWrapper>
	);
};
