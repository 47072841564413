import { CardPretitle } from '@playbooks/interface/cards';
import { PreviewCard } from 'molecules/cards';

const TeamAboutCard = ({ team }) => {
	// Render
	return (
		<PreviewCard
			photo={team.thumbnail}
			title={team.name}
			subtitle={'@' + team.uuid}
			tailwind={{
				card: { bgColor: '', border: '', borderRadius: '', spacing: '' },
				body: { space: '' },
			}}>
			<CardPretitle fontSize='text-sm' textTransform='uppercase' tracking='tracking-widest'>
				{team.membership} Plan
			</CardPretitle>
		</PreviewCard>
	);
};

export { TeamAboutCard };
