import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardIcon,
	CardImg,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const PhotoCard = ({ icon, badge, photo, alt, title, text, href, loading, tailwind, children }) => {
	// Render
	if (loading) return <Skeleton type='photo' count={1} tailwind={tailwind} />;
	return (
		<Card
			display='flex-column'
			height='h-full'
			hover='h:border-opacity-100 dark:h:border-opacity-100 h:shadow-md'
			spacing='p-4'
			{...tailwind?.card}>
			<LinkWrapper href={href}>
				<CardHeader
					aspect='aspect-[1/1]'
					display='flex-middle'
					overflow='overflow-hidden'
					spacing=''
					width='w-full'
					{...tailwind?.header}>
					{badge}
					{photo ? (
						<CardImg src={photo} alt={title + ' photo'} {...tailwind?.photo} />
					) : (
						<CardIcon icon={icon} {...tailwind?.icon} />
					)}
				</CardHeader>
			</LinkWrapper>
			<CardBody spacing='pt-4' {...tailwind?.body}>
				{title && (
					<LinkWrapper href={href} textTransform='h:underline'>
						<CardTitle>{title}</CardTitle>
					</LinkWrapper>
				)}
				{text && <CardText>{text}</CardText>}
			</CardBody>
			{children && (
				<CardFooter spacing='pt-4' {...tailwind?.footer}>
					{children}
				</CardFooter>
			)}
		</Card>
	);
};

export { PhotoCard };
