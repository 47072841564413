import { BorderBtn } from '@playbooks/interface/buttons';
import { FormLabel } from '@playbooks/interface/forms';
import { Div, Img } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { borderProps, tailwindClassBuilder } from '@playbooks/interface/tailwind';
import { useToast } from 'contexts';
import * as Filestack from 'filestack-js';

type iFilestack = {
	type?: string;
	title?: string;
	value: string;
	dimensions?: { width: number; height: number };
	onChange?: any;
	className?: string;
	tailwind?: any;
};

const FilestackPhotoWrapper = ({
	type = '',
	title,
	value,
	dimensions = { width: 1, height: 1 },
	className,
	onChange,
	tailwind,
}: iFilestack) => {
	const base = {
		aspect: 'aspect-[1/1]',
		bgColor: 'bg-gray-100 dark:bg-gray-800',
		border: 'border-2',
		...borderProps,
		borderRadius: 'rounded-md',
		flex: 'flex-middle',
		overflow: 'overflow-hidden',
		width: 'w-20',
	};
	const classes = tailwindClassBuilder({ ...base, ...tailwind, className });
	const client = Filestack.init(process.env.NEXT_PUBLIC_FILESTACK_KEY);
	const toast = useToast();

	const options = {
		accept: ['image/*'],
		maxFiles: 1,
		minFiles: 1,
		imageMin: [480, 480],
		imageMax: [1080, 1080],
		imageDim: [1080, 1080],
		uploadInBackground: false,
		fromSources: ['local_file_system', 'googledrive', 'url'],
		onUploadDone: data => onUploadDone(data),
		onFileUploadFailed: e => toast.showError(e),
		transformations: {
			rotate: true,
			crop: {
				force: true,
				aspectRatio: dimensions.width / dimensions.height,
			},
		},
	};

	// Methods
	const onUploadDone = data => {
		onChange(data.filesUploaded[0]);
	};

	const onOpen = () => {
		client.picker(options).open();
	};

	// Render
	switch (type) {
		case 'cover':
			return (
				<Div onClick={onOpen} group='group' cursor='cursor-pointer' className={classes}>
					{value ? <Img src={value} /> : <FadIcon icon='image' fontSize='text-4xl' />}
					<Div
						transition='transition-all'
						animation='ease'
						bgColor='bg-transparent group-hover:bg-black'
						bgOpacity={value ? 'group-hover:bg-opacity-75' : 'group-hover:bg-opacity-25'}
						display='hidden group-hover:flex-middle'
						position='absolute'
						inset='inset-0'
						zIndex='z-5'>
						<FadIcon icon='image' fontSize='text-4xl' />
					</Div>
				</Div>
			);
		default:
			return (
				<Div display='flex-start' space='space-x-8'>
					<Div className={classes}>{value && <Img src={value} />}</Div>
					<Div className='space-y-2'>
						<FormLabel display='block' fontSize='text-base' fontWeight='font-medium'>
							{title}
						</FormLabel>
						<BorderBtn size='xs' onClick={onOpen}>
							Upload
						</BorderBtn>
					</Div>
				</Div>
			);
	}
};

export { FilestackPhotoWrapper };

// Docs:
// https://dev.filestack.com
// https://filestack.github.io/filestack-js/
// https://www.npmjs.com/package/filestack-react
// https://www.filestack.com/docs/transformations/ui/web/
// https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#fromsources
