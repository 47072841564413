import { Card, CardBody } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const PhotoSkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card bgColor='' border='border' borderRadius='rounded-md' display='flex-column' spacing='p-4' {...tailwind?.card}>
			<SkeletonWrapper width='100%' className='aspect-[1/1] rounded-md' />
			<CardBody space='space-y-2' spacing='pt-4'>
				<h5>
					<SkeletonWrapper width='50%' />
				</h5>
				<h6>
					<SkeletonWrapper width='75%' />
				</h6>
			</CardBody>
		</Card>
	);
};

export { PhotoSkeleton };
