import { useEffect, useState } from 'react';

import { Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { LegalDisclaimer } from 'components/auth/legal-disclaimer';
import { RegisterFields } from 'components/auth/register-fields';
import { GithubOAuthBtn } from 'components/oauth/github-oauth-btn';
import { useStore, useToast } from 'contexts';

const AuthFlowRegister = ({ open, setOpen, onClose, onView, onNext }) => {
	const [user, setUser] = useState<any>({});
	const [taskRunning, setTaskRunning] = useState(false);
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		return () => setTaskRunning(false);
	}, []);

	// Methods
	const onSave = async () => {
		try {
			setTaskRunning(true);
			const response = await store.createRecord({ url: '/auth/register', data: user });
			await onNext(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Form onSubmit={onSubmit}>
			<ModalHeader onClose={onClose}>
				<ModalTitle>Register</ModalTitle>
			</ModalHeader>
			<ModalBody size='sm'>
				<RegisterFields user={user} setUser={setUser} />
			</ModalBody>
			<ModalFooter display='block' space='space-y-4'>
				<Btn type='submit' size='lg' width='w-full' taskRunning={taskRunning}>
					Register
				</Btn>
				<GithubOAuthBtn onNext={onNext} />
				<LegalDisclaimer />
			</ModalFooter>
		</Form>
	);
};

export { AuthFlowRegister };
