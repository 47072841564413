import { Badge } from '@playbooks/interface/badges';
import { BtnWrapper } from '@playbooks/interface/buttons';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';
import { HtmlType } from 'types';

type iPreviewCard = HtmlType & {
	icon?: string;
	photo?: string;
	pretitle?: string;
	title?: string;
	subtitle?: string;
	text?: string;
	meta?: any;
	href: string;
	loading?: string;
	tailwind?: string;
};

export const PreviewCard = ({
	icon,
	photo,
	alt,
	status,
	pretitle,
	title,
	subtitle,
	text,
	meta,
	loading,
	tailwind,
	children,
}: iPreviewCard) => {
	// Render
	if (loading) return <Skeleton type='preview' tailwind={tailwind} />;
	return (
		<Card
			border='border-b'
			borderRadius=''
			display='flex-between'
			hover='group-hover:border-opacity-100 group-hover:dark:border-opacity-100'
			space='space-x-4'
			spacing='py-4'
			{...tailwind?.card}>
			<Span display='flex-start' flex='grow' space='space-x-4' {...tailwind?.span}>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					flex='shrink-0'
					overflow='overflow-hidden'
					spacing=''
					width='w-12'
					{...tailwind?.header}>
					{photo ? (
						<CardImg src={photo} alt={title + ' photo'} width='w-full' {...tailwind?.photo} />
					) : (
						<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
					)}
				</CardHeader>
				<CardBody spacing='' width='w-full' {...tailwind?.body}>
					{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
					{title && (
						<CardTitle {...tailwind?.title}>
							<Span>{title}</Span>
							{status && <Badge size='xs'>{status}</Badge>}
						</CardTitle>
					)}
					{subtitle && <CardSubtitle {...tailwind?.subtitle}>{subtitle}</CardSubtitle>}
					{text && (
						<CardSubtitle fontSize='text-sm' {...tailwind?.text}>
							{text}
						</CardSubtitle>
					)}
					{meta}
				</CardBody>
			</Span>
			<CardActions display='flex-end' flex='shrink-0'>
				{children}
			</CardActions>
		</Card>
	);
};

export const PreviewBtnCard = props => (
	<BtnWrapper onClick={props.onClick} group='group' className='w-full'>
		<PreviewCard {...props} />
	</BtnWrapper>
);

export const PreviewLinkCard = props => (
	<LinkWrapper href={props.href} group='group' className='w-full'>
		<PreviewCard {...props} />
	</LinkWrapper>
);
