import { useEffect, useState } from 'react';

import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { AuthBtn } from 'components/auth/auth-btn';
import { useAccount, useStore, useToast } from 'contexts';
import { toShortNumber } from 'utils';

export const UpvoteBtn = ({ type = 'icon', model, rootApi }) => {
	const [upvote, setUpvote] = useState<any>({});
	const [count, setCount] = useState(0);
	const [taskRunning, setTaskRunning] = useState(false);
	const account = useAccount();
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		setCount(model?.meta?.totalUpvotes || 0);
		setUpvote(model?.social?.follower || {});
	}, [model?.social]);

	// Methods
	const onClick = async () => {
		try {
			setTaskRunning(true);
			const response = await store.queryRecord({ url: `${rootApi}/upvote` });
			setUpvote(response.data?.id || {});
			setCount(response.data?.id ? count + 1 : count - 1);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	const props = { upvote, count, taskRunning, onClick };

	// Render
	switch (type) {
		case 'card':
			return <UpvoteCardBtn {...props} />;
		case 'comment':
			return <UpvoteCommentBtn {...props} />;
		case 'hero':
			return <UpvoteHeroBtn {...props} />;
	}
};

const UpvoteCardBtn = ({ upvote, count, taskRunning, onClick }) => (
	<AuthBtn
		type='accent'
		bgOpacity='bg-opacity-25 dark:bg-opacity-25'
		size='w-16 h-16'
		alt='upvote button'
		active={upvote.id}
		taskRunning={taskRunning}
		onClick={onClick}>
		<Span display='flex-column' space='space-y-2'>
			<FarIcon icon='caret-up' fontSize='text-base' />
			<Span fontSize='text-sm'>{toShortNumber(count || 0)}</Span>
		</Span>
	</AuthBtn>
);

const UpvoteCommentBtn = ({ upvote, count, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		size='xxs'
		alt='upvote button'
		active={upvote.id}
		prevIcon='caret-up'
		taskRunning={taskRunning}
		onClick={onClick}>
		{count}
	</AuthBtn>
);

const UpvoteHeroBtn = ({ upvote, count, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		onClick={onClick}
		alt='upvote button'
		active={upvote.id}
		prevIcon='caret-up'
		taskRunning={taskRunning}>
		{toShortNumber(count) + ' Upvotes'}
	</AuthBtn>
);
