import { Avatar, AvatarImg } from '@playbooks/interface/avatars';
import { LinkWrapper } from '@playbooks/interface/links';

export const ThumbnailAvatar = ({ thumbnail, title, href }) => {
	// Render
	return (
		<LinkWrapper href={href} flex='shrink-0'>
			<Avatar flex='shrink-0'>
				<AvatarImg size='md' src={thumbnail} alt={`${title} photo`} borderRadius='rounded-sm' />
			</Avatar>
		</LinkWrapper>
	);
};
